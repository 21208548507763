export const PRESET_FORM_VALUES = Object.freeze({
  name: '',
  linkedAccount: '',
  //
  assetAccount: '',
  interestIncome: ''
})

export function data() {
  return {
    assetAccounts: [],
    interestIncomeAccounts: [],
    //
    bankAccountTypes: [],
    //
    defaultBankProviderId: 0,
    formComplete: false,
    formData: {
      ...PRESET_FORM_VALUES
    },
    loading: true,
    bottom: null,
    medium: null,
    //
    plaidSuccessful: false,
    financialInstitutionID: null,
    plaidInstitutionId: null,
    //
    plaidAccounts: [],
    //
    step: 0,
    //
    buttonPreviousAllowed: true,
    buttonPreviousNote: '',
    //
    buttonNextAllowed: true,
    buttonNextNote: '',
    //
    buttonLinkAllowed: true
  }
}
