//
import { PRESET_FORM_VALUES } from './data'
//
import { bankDataProviderStore } from '@/services/BankDataProvider/store'
import { listBankAccountTypes } from '@/services/BankAccounts/BankAccountType/List'
import { getGLAccountsList } from '@/services/GeneralLedger/Account/List'
//
import { notifyError, notifyMessage } from '@/services/notify'
import { ValidationError } from '@/services/errors'
import kms from '@/services/kms'
//
import moment from 'moment'
import _get from 'lodash/get'
//

//
export const methods = {
  async refresh() {},

  previousStep() {
    this.step--
  },
  nextStep() {
    this.step++
  },

  onPlaidSuccess({
    // plaidAccounts,
    // institutionId,
    metadata,

    financialInstitutionID,
    providerInstitutionID
  }) {
    this.step = 1

    this.plaidAccounts = metadata.accounts
    this.financialInstitutionId = financialInstitutionID
    this.plaidInstitutionId = providerInstitutionID

    this.plaidSuccessful = true
  },

  async procureAccountTypesList() {
    const { successful, message, accountTypesParsed } = await listBankAccountTypes({
      params: {
        hoaID: this.hoaId
      }
    })
    if (!successful) {
      notifyError(message)
      return {
        accountTypesParsed
      }
    }

    return {
      accountTypesParsed
    }
  },

  checkStep() {
    const step = this.step

    if (step === 0) {
      this.buttonPreviousNote = 'There are no previous steps.'
      this.buttonPreviousAllowed = false

      if (this.plaidSuccessful) {
        this.buttonNextAllowed = true
        this.buttonNextNote = ''
      } else {
        this.buttonNextAllowed = false
        this.buttonNextNote = 'Please link an account with Plaid to continue.'
      }
    } else if (step === 1) {
      this.buttonNextNote = ''
      this.buttonPreviousNote = ''

      this.buttonPreviousAllowed = true
      this.buttonNextAllowed = true
    } else if (step === 2) {
      this.buttonNextNote = 'There are no more steps.'
      this.buttonPreviousNote = ''

      this.buttonPreviousAllowed = true
      this.buttonNextAllowed = false
    }
  },

  async procureGLAccountsList() {
    /*
		  for getting the "Income" & "Asset" accounts
		*/
    const { successful, message, parsedAccounts } = await getGLAccountsList({
      parseTypes: true,
      params: {
        hoaID: this.hoaId
      }
    })
    if (!successful) {
      notifyError(message)
      return {
        parsedAccounts: {}
      }
    }

    return {
      parsedAccounts
    }
  },

  async resetForm() {
    this.loading = true
    this.formData = { ...PRESET_FORM_VALUES }
    if (this.$refs.form) {
      this.$refs.form.reset()
    }

    await this.getProvider()

    const { parsedAccounts } = await this.procureGLAccountsList()
    this.assetAccounts = _get(parsedAccounts, ['Asset'], [])
    this.interestIncomeAccounts = _get(parsedAccounts, ['Income'], [])

    console.debug('resetForm plaidaccounts=' + JSON.stringify(this.plaidAccountIds))

    this.loading = false
  },

  async getProvider() {
    await bankDataProviderStore
      .dispatch('getProvider', {
        providerName: 'Plaid',
        isDefault: true
      })
      .then(({ result }) => {
        if (result) {
          this.defaultBankProviderId = result.bankDataProviderID

          console.debug('this.defaultBankProviderId=' + this.defaultBankProviderId)
        }
      })
  },

  getFormPayload() {
    const today = moment()

    const plaidAccountMask = _get(this, ['formData', 'linkedAccount', 'mask'], '')
    const plaidAccountType = _get(this, ['formData', 'linkedAccount', 'name'], '')

    return {
      hoaID: this.hoaId,
      //
      // nickname of the account
      name: this.formData.name,
      //
      // internal "bank data provider" id of "Plaid"
      bankDataProviderID: parseInt(this.defaultBankProviderId),
      //
      //
      bankGLAccountID: this.formData.assetAccount,
      interestIncomeGLAccountID: this.formData.interestIncome,
      //
      //
      //  plaid data:
      //    institution (plaidInstitutionId):     e.g. the id of the: bank, credit union, etc.
      //    account (bankDataProviderAccountID):  e.g. checking, savings, mortgage, etc.
      //
      //  internal data:
      //    institution (financialInstitutionID): e.g. the id of the: bank, credit union, etc.
      //
      plaidInstitutionId: this.plaidInstitutionId,
      bankDataProviderAccountID: this.formData.linkedAccount.id,
      financialInstitutionID: this.financialInstitutionId,
      //
      createdDate: today.toDate(),
      modifiedDate: today.toDate(),
      //
      //
      plaidAccountType,
      plaidAccountMask
      /////////////////////////////////////
    }
  },

  async onFormSubmit() {
    if (this.plaidInstitutionId == null || this.financialInstitutionId == null) {
      notifyError('Please link to your Plaid Bank Account.')

      console.info('this.plaidInstitutionId', this.plaidInstitutionId)
      console.info('this.financialInstitutionId', this.financialInstitutionId)

      return
    }

    try {
      this.loading = true

      const payload = this.getFormPayload()

      const path = `/BankAccounts/BankAccount/UpsertBankAccountForClient`
      const results = await kms.post(path, payload)

      if (results.bankAccountID >= 0) {
        this.formComplete = true
        this.resetForm()
        this.$router.push('/bankAccounts')
        notifyMessage(`${results.name} was successfully added.`)
      } else {
        notifyError('There was a problem creating your Linked Bank Account.')
      }
    } catch (e) {
      // If this is a validation error, get the details for each field
      if (e instanceof ValidationError) {
        this.$refs.form.setErrors(e.fields)
      }

      notifyError(e)
    }

    this.loading = false
  }
}
