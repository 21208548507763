<template>
  <PageContent :title="$t('createLinked.title')">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
          <b-steps
            v-model="step"
            :position="bottom"
            :label-position="medium"
            :size="medium"
            :has-navigation="false"
          >
            <b-step-item label="Link" icon="account-key">
              <div class="box is-8">
                <plaidLink :onPlaidSuccess="onPlaidSuccess" />
              </div>
            </b-step-item>

            <b-step-item label="Institution" icon="account">
              <fieldset class="card">
                <div class="column is-8">
                  <valid-input
                    label="Account Name"
                    name="Account Name"
                    type="text"
                    vid="name"
                    placeholder="Name"
                    rules="required"
                    v-model="formData.name"
                  />
                </div>

                <div class="column is-8">
                  <valid-select
                    v-model="formData.linkedAccount"
                    label="Linked Account"
                    placeholder="Linked Account"
                    vid="linkedAccount"
                    rules="required"
                  >
                    <option v-for="option in plaidAccounts" :value="option" :key="option.id"
                      >{{ option.mask }} - {{ option.name }}</option
                    >
                  </valid-select>
                </div>
              </fieldset>
            </b-step-item>

            <b-step-item label="Account" icon="account-plus" class="card" disabled>
              <fieldset>
                <div class="column is-8">
                  <b-field label="Asset Account">
                    <b-select
                      v-model="formData.assetAccount"
                      placeholder="Asset Account"
                      vid="assetAccount"
                      rules="required"
                    >
                      <option
                        :value="null"
                        disabled
                        :style="{ color: '#888' }"
                        v-if="!loading && assetAccounts && assetAccounts.length === 0"
                      >
                        An asset account has not yet been added.
                      </option>
                      <option
                        v-for="option in assetAccounts"
                        :value="option.value"
                        :key="option.value"
                        >{{ option.label }}</option
                      >
                    </b-select>
                  </b-field>
                </div>

                <div class="column is-8">
                  <b-field label="Interest Income Account">
                    <b-select
                      v-model="formData.interestIncome"
                      placeholder="Interest Income"
                      vid="interestIncome"
                      rules="required"
                    >
                      <option
                        :value="null"
                        disabled
                        :style="{ color: '#888' }"
                        v-if="
                          !loading && interestIncomeAccounts && interestIncomeAccounts.length === 0
                        "
                      >
                        An interest income account has not yet been added.
                      </option>
                      <option
                        v-for="option in interestIncomeAccounts"
                        :value="option.value"
                        :key="option.value"
                        >{{ option.label }}</option
                      >
                    </b-select>
                  </b-field>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>

                <div class="column is-8">
                  <!-- <b-button v-if="buttonLinkAllowed" type="submit" class="is-primary" :disabled="loading" size="is-medium">
												Link Account
											</b-button> -->
                  <button type="submit" class="button is-primary is-rounded" :disabled="loading">
                    Link Account
                  </button>
                </div>
              </fieldset>
            </b-step-item>
          </b-steps>

          <div :style="{ padding: '1rem', display: 'flex', justifyContent: 'space-between' }">
            <b-tooltip
              v-if="!buttonPreviousAllowed"
              :label="buttonPreviousNote"
              :triggers="['click']"
              position="is-right"
              multilined
              size="is-small"
            >
              <div :style="{ position: 'relative' }">
                <b-button :style="{ position: 'absolute' }" size="is-medium" disabled
                  >Previous</b-button
                >
                <b-button :style="{ position: 'relative', opacity: 0.1 }" size="is-medium"
                  >Previous</b-button
                >
              </div>
            </b-tooltip>

            <b-button v-if="buttonPreviousAllowed" @click="previousStep" size="is-medium"
              >Previous</b-button
            >

            <div>
              <b-tooltip
                v-if="!buttonNextAllowed"
                :active="!buttonNextAllowed"
                :label="buttonNextNote"
                :triggers="['click']"
                position="is-left"
                multilined
                size="is-small"
              >
                <div :style="{ position: 'relative' }">
                  <b-button :style="{ position: 'absolute' }" size="is-medium" disabled
                    >Next</b-button
                  >
                  <b-button :style="{ position: 'relative', opacity: 0.1 }" size="is-medium"
                    >Next</b-button
                  >
                </div>
              </b-tooltip>

              <b-button v-if="buttonNextAllowed" @click="nextStep" size="is-medium">Next</b-button>

              <div :style="{ padding: '0 10px', display: 'inline-block' }"></div>

              <!-- <b-tooltip 
										v-if="!buttonLinkAllowed"
										
										:active="!buttonLinkAllowed"
										:label="buttonLinkNote"
										
										position="is-left"
										multilined
										>
												<b-button 
														:disabled="true" 
														size="is-medium"
												>Link Account</b-button>
										</b-tooltip> -->
            </div>
          </div>
        </form>
      </transition>
    </ValidationObserver>
  </PageContent>
</template>

<script>
//
//
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'
import PageContent from '@/containers/PageContent'
import PlaidLink from '@/components/Plaid/Link'
//
//
import { ValidationObserver, ValidationProvider } from 'vee-validate'
//
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { watch } from './keys/watch'
//
//
export default {
  components: {
    PlaidLink,
    PageContent,
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider
  },

  computed,
  data,
  methods,
  mounted,
  watch,

  i18n: {
    messages: {
      en: { createLinked: { title: 'Add Linked Account' } }
    }
  }
}
</script>

<style lang="scss" scoped>
button.disabled {
  background-color: white;
  border-color: #dbdbdb;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.5;
}
</style>
