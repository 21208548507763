/*
  example:
    import { listBankAccountTypes } from '@/services/BankAccounts/BankAccountType/List';

    const { successful, message } = await listBankAccountTypes ({
      params: {
				hoaID
      }
    });
    if (!successful) {
      return;
    }
*/

import kms from '@/services/kms'

export async function listBankAccountTypes({ params } = {}) {
  let message = `The bank account types list could not be retrieved.`

  try {
    const { results: accountTypes } = await kms.get(`/BankAccounts/BankAccountType/List`, {
      params
    })

    const optional = {}
    optional.accountTypesParsed = accountTypes.map(account => {
      return {
        value: account.bankAccountTypeID,
        label: account.name
      }
    })

    return {
      successful: true,
      message: '',

      accountTypes,
      ...optional
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,

    accountTypes: [],
    accountTypesParsed: []
  }
}

///////
